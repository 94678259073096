import React from "react";
import styled from "styled-components";
import Paragraph from "../components/blog/Paragraph";
import BlogTitle from "../components/blog/BlogTitle";
import ImageWithCaption from "../components/blog/ImageWithCaption";
import BlogHeading2 from "../components/blog/BlogHeading2";
import InternalLink from "../components/blog/InternalLink";
import ExternalLink from "../components/blog/ExternalLink";
import BlockQuote from "../components/blog/BlockQuote";
import UnorderedList from "../components/blog/UnorderedList";
import UnorderedListItem from "../components/blog/UnorderedListItem";
import {StaticImage} from "gatsby-plugin-image";
import SEO from "../components/SEO";
import BlogHeading3 from "../components/blog/BlogHeading3";
import { withPrefix } from "gatsby";

const BlogPostWrapper = styled.main`
  padding: var(--main-padding-mob);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledMainContent = styled.div`  
  max-width: 750px; 
`;

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const BlogPost = ({location}) =>{
    return <BlogPostWrapper> <StyledMainContent>
        <SEO
            title="Shopping List For Insulin Resistance Diet"
            description="One of the best ways to reverse or manage insulin resistance is through diet. Here is a shopping list for insulin resistance to start you off."
            location={location}
            pageType="article"
        />

        <HeroSection>
            <BlogTitle>
                Shopping List For Insulin Resistance Diet
            </BlogTitle>
            <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/shopping-list-for-insulin-resistance-diet.jpg" alt="Shopping List For Insulin Resistance Diet"/>
                <em>Photo by <ExternalLink to="https://unsplash.com/@jannisbrandt?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Jannis Brandt</ExternalLink> on <ExternalLink to ="https://unsplash.com/s/photos/diabetes?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
            </ImageWithCaption>
        </HeroSection>
        <Paragraph>
            Added sugar has unfortunately become the norm in Western diets. Food products are riddled with it, even the ones that don't taste sweet, such as ketchup, different sauces, condiments, salad dressings, and similar.
        </Paragraph>
        <Paragraph>
            As a result of this added sugar pandemic and widespread unbalanced diets, <ExternalLink to ="https://www.cdc.gov/chronicdisease/resources/publications/aag/diabetes.htm">over 37.3 Americans</ExternalLink> have developed type 2 diabetes, according to the CDC.
        </Paragraph>
        <Paragraph>
            Insulin resistance represents the starting point of type 2 diabetes, and it's best to reverse it while it's in the early stages.
        </Paragraph>
        <Paragraph>
            Reversing insulin resistance requires keeping your blood sugar low by following a low carb diet in combination with other lifestyle modifications.
        </Paragraph>
        <Paragraph>
            For a more detailed outline of which foods to eat and which to avoid, we have created a shopping list for insulin resistance diet.
        </Paragraph>
        <Paragraph>
            <b>Key takeaways from this article:</b>
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Insulin resistance is a prediabetes condition that can be reversed before it develops into diabetes type 2.</UnorderedListItem>
            <UnorderedListItem>If not acted on, insulin resistance can lead to a wide range of health problems.</UnorderedListItem>
            <UnorderedListItem>What foods to eat on an insulin resistance diet and why.</UnorderedListItem>
            <UnorderedListItem>What foods to avoid on an insulin resistance diet and why.</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Insulin resistance diet shopping list printable
        </BlogHeading2>
        <Paragraph>
            <ExternalLink to={withPrefix("Shopping List For Insulin Resistance Diet.pdf")}>Click here to download the free insulin resistance grocery list PDF.</ExternalLink>
        </Paragraph>
        <BlogHeading2>
            What is insulin resistance?
        </BlogHeading2>
        <Paragraph>
            Insulin is a hormone produced by your pancreas.
        </Paragraph>
        <Paragraph>
            Whenever you eat a meal - any type of meal, not only when you consume sweet things - your blood sugar (blood glucose) level rises.
        </Paragraph>
        <Paragraph>
            If your pancreas functions normally, the elevated blood sugar signals to the pancreas to produce insulin. 
        </Paragraph>
        <Paragraph>
            Insulin is then released into the blood, which enables your body cells (such as muscle, brain, and liver cells) to absorb the blood sugar and use it as essential energy.
        </Paragraph>
        <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/2022-17-healthy-pancreas-graphic.jpg" alt="Graph outlining the function of a healthy pancreas."/>
        </ImageWithCaption>
        <Paragraph>
            Insulin resistance happens when your body cells are exposed to too much blood sugar for a prolonged period of time. This reduces their ability to absorb sugar from the blood, resulting in extremely high blood sugar levels. 
        </Paragraph>
        <Paragraph>
            Since the blood sugar levels are always high, pancreas continues to produce insulin, but the insulin doesn't have much effect on the overexposed cells. This is called insulin resistance.
        </Paragraph>
        <Paragraph>
            If the problem persists, the pancreas cannot keep up with producing so much insulin. Over time, pancreatic cells start to fatigue and less and less insulin is produced.
        </Paragraph>
        <Paragraph>
            This is when insulin resistance transitions into diabetes type 2.
        </Paragraph>
        <Paragraph>
            If you want to learn more on what to eat on a diabetic diet, check out our <InternalLink to = "/diabetic-shopping-list/">diabetic shopping list</InternalLink>!
        </Paragraph>
        <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/2022-17-insulin-resistance-graphic.jpg" alt="Graph outlining the body processes of insulin resistance."/>
        </ImageWithCaption>
        <BlogHeading2>
            Why is insulin resistance bad?
        </BlogHeading2>
        <Paragraph>
            Insulin resistance can lead to many detrimental health conditions.
        </Paragraph>
        <Paragraph>
            The most well known consequence of untreated insulin resistance is type 2 diabetes. The most well known consequence of untreated insulin resistance is type 2 diabetes. 
        </Paragraph>
        <Paragraph>
            Diabetes then leads to more serious health problems such as <b>cardiovascular disease, kidney disease, nerve damage</b>, and more.
        </Paragraph>
        <Paragraph>
            Other health conditions linked to insulin resistance include:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>Polycystic ovary syndrome (PCOS) </b></UnorderedListItem>
            <UnorderedListItem><b>Metabolic syndrome </b></UnorderedListItem>
            <UnorderedListItem><b>Obesity</b></UnorderedListItem>
            <UnorderedListItem><b>Nonalcoholic fatty liver disease</b></UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            Another health condition that can be caused by insulin resistance is major depressive disorder. In fact, <ExternalLink to="https://med.stanford.edu/news/all-news/2021/09/insulin-resistance-major-depressive-disorder.html">studies have shown</ExternalLink> that insulin resistance doubles your chances of developing<b> major depressive disorder.</b>
        </Paragraph>
        <BlogHeading2>
            What is the main cause of insulin resistance?
        </BlogHeading2>
        <Paragraph>
            The exact cause of insulin resistance is not clearly defined., However, scientists believe that both genetic and lifestyle factors play a role in the development of this disease.
        </Paragraph>
        <Paragraph>
            Causes of insulin resistance include:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>Genes.</b> A family history of type 2 diabetes can increase the risk of insulin resistance.</UnorderedListItem>
            <UnorderedListItem><b>Age.</b> You are more likely to develop insulin resistance as you get older.</UnorderedListItem>
            <UnorderedListItem><b>Obesity.</b> Having excess body fat, especially around the area of the abdomen (belly fat), is the primary cause of insulin resistance.</UnorderedListItem>
            <UnorderedListItem><b>Physical inactivity.</b> Lack of exercise can decrease insulin sensitivity and lead to weight gain, which is all connected to insulin resistance.</UnorderedListItem>
            <UnorderedListItem><b>Medication.</b> There are certain types of medications that can increase insulin resistance, such as medications for high blood pressure, psychiatric conditions, and HIV.</UnorderedListItem>
            <UnorderedListItem><b>Smoking.</b></UnorderedListItem>
            <UnorderedListItem><b>Stress.</b> Stress increases the hormone cortisol, which over time can contribute to the development of insulin resistance.</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            And finally, last but not least:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>Diet. </b>Foods that are overly processed and high in carbohydrates and saturated fats lead to sugar spikes and are known to contribute to insulin resistance.</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            How to improve insulin sensitivity through diet
        </BlogHeading2>
        <Paragraph>
            The easiest way to find out how specific foods will influence your blood sugar and insulin levels is through what is called the glycemic index (GI). The glycemic index represents the measure of speed and intensity with which a blood sugar spike occurs after eating a certain food.
        </Paragraph>
        <Paragraph>
            A high GI of a food means that the food causes sudden spikes in blood sugar that don't last very long.
        </Paragraph>
        <Paragraph>
            For example, white bread, white rice, cornflakes, potatoes, and crackers all have a very high glycemic index and cause a huge increase in blood sugar levels.
        </Paragraph>
        <Paragraph>
            On the other hand, lentils, chickpeas, raw apples, and green veggies have a low glycemic index. They are safe to consume on an insulin resistance diet.
        </Paragraph>
        <Paragraph>
            By looking at the <ExternalLink to ="https://www.health.harvard.edu/diseases-and-conditions/glycemic-index-and-glycemic-load-for-100-foods">glycemic index of foods</ExternalLink> and following a low glycemic eating regime, you will find safe food options for your insulin resistance diet plan.
        </Paragraph>
        <BlogHeading2>
            Insulin resistance diet food list
        </BlogHeading2>
        <Paragraph>
            In order to increase insulin sensitivity (and reduce insulin resistance), it is important to incorporate a <InternalLink to ="/healthy-pantry-staples/">healthy diet</InternalLink> into your lifestyle; one that won't cause your sugar levels to rise too much.
        </Paragraph>
        <Paragraph>
            You can do that by following a low carb and low glycemic foods diet, helping your body metabolize sugar better.
        </Paragraph>
        <Paragraph>
            Here's what to eat when you're insulin resistant:
        </Paragraph>
        <BlogHeading3>
            Non-starchy vegetables
        </BlogHeading3>
        <Paragraph>
            Veggies are essential for any healthy diet. They are low in carbs and high in fiber and antioxidants, making them perfect for an insulin resistance diet.
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Tomatoes</UnorderedListItem>
            <UnorderedListItem>Pepper</UnorderedListItem>
            <UnorderedListItem>Kale</UnorderedListItem>
            <UnorderedListItem>Asparagus</UnorderedListItem>
            <UnorderedListItem>Brussels sprouts</UnorderedListItem>
            <UnorderedListItem>Spinach</UnorderedListItem>
            <UnorderedListItem>Broccoli</UnorderedListItem>
            <UnorderedListItem>Garlic</UnorderedListItem>
            <UnorderedListItem>Onion</UnorderedListItem>
            <UnorderedListItem>Cauliflower</UnorderedListItem>
            <UnorderedListItem>Cabbage</UnorderedListItem>
            <UnorderedListItem>Carrots</UnorderedListItem>
        </UnorderedList>
        <ImageWithCaption>
            <StaticImage src="../assets/images/blogs/2022-17-plate-of roasted-sweet-potato-sticks.jpg" alt="A plate of roasted and seasoned sweet potato sticks."/>
            <em>Photo by <ExternalLink to="https://unsplash.com/@moniqa?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Monika Grabkowska</ExternalLink> on <ExternalLink to = "https://unsplash.com/s/photos/sweet-potato?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
        </ImageWithCaption>
        <BlogHeading3>
            Starchy vegetables
        </BlogHeading3>
        <Paragraph>
            When including starchy veggies, avoid those with a high glycemic index such as corn and potatoes. 
        </Paragraph>
        <Paragraph>
            Instead look for:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Sweet potatoes</UnorderedListItem>
            <UnorderedListItem>Beets</UnorderedListItem>
            <UnorderedListItem>Butternut squash</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Fruit
        </BlogHeading3>
        <Paragraph>
            Fruit can have a high glycemic index, so make sure you are eating options that are low in sugar:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Berries</UnorderedListItem>
            <UnorderedListItem>Citrus fruits</UnorderedListItem>
            <UnorderedListItem>Green apples</UnorderedListItem>
        </UnorderedList>
        <BlockQuote>
            <b>PRO TIP:</b> Add the items from this list to a <InternalLink to = "/#download-section">free shopping list app</InternalLink> on your phone to not forget them the next time you're in the store!
        </BlockQuote>
        <BlogHeading3>
            Dairy
        </BlogHeading3>
        <Paragraph>
            Dairy contains a natural sugar called lactose that can cause digestive problems for some people. 
        </Paragraph>
        <Paragraph>
            If it doesn't cause any issues in your gut, you can still consume dairy. Go for low-fat options if you can.
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Milk</UnorderedListItem>
            <UnorderedListItem>Cheese</UnorderedListItem>
            <UnorderedListItem>Yogurt</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Whole grains
        </BlogHeading3>
        <Paragraph>
            Although carbs should be avoided on this diet, some complex carbohydrates can be good for you. Still, consume these whole grain options in moderation.
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Oatmeal</UnorderedListItem>
            <UnorderedListItem>Whole grain flour</UnorderedListItem>
            <UnorderedListItem>Brown rice</UnorderedListItem>
            <UnorderedListItem>Bulgur</UnorderedListItem>
            <UnorderedListItem>Quinoa</UnorderedListItem>
            <UnorderedListItem>Millet</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Beans and legumes
        </BlogHeading3>
        <Paragraph>
            Beans and legumes have a very low glycemic index and are a great source of vegan and vegetarian protein as well.
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Black beans</UnorderedListItem>
            <UnorderedListItem>Lentils</UnorderedListItem>
            <UnorderedListItem>Chickpeas</UnorderedListItem>
            <UnorderedListItem>Peas</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Lean proteins
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Poultry (chicken breast, turkey)</UnorderedListItem>
            <UnorderedListItem>Tofu</UnorderedListItem>
            <UnorderedListItem>Tempeh</UnorderedListItem>
            <UnorderedListItem>Soy</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Fish
        </BlogHeading3>
        <Paragraph>
            Fish is high in omega 3 fatty acids and lean proteins, making it a highly recommended source of protein for an insulin resistance diet.
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Salmon</UnorderedListItem>
            <UnorderedListItem>Tuna</UnorderedListItem>
            <UnorderedListItem>Herring</UnorderedListItem>
            <UnorderedListItem>Sardines</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Healthy fats
        </BlogHeading3>
        <Paragraph>
            Healthy fats are a great source of energy on a low carb diet, especially the ones that contain omega 3 fatty acids and monosaturated fatty acids. Try these:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Olive oil</UnorderedListItem>
            <UnorderedListItem>Avocado</UnorderedListItem>
            <UnorderedListItem>Nut butters (peanut butter, almond butter, tahini)</UnorderedListItem>
        </UnorderedList>
        <ImageWithCaption>
            <StaticImage src="../assets/images/blogs/2022-17-one-halved-avocado.jpg" alt="One halved avocado with the pit in the center."/>
            <em>Photo by <ExternalLink to="https://unsplash.com/@ikredenets?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Irene Kredenets</ExternalLink> on <ExternalLink to = "https://unsplash.com/s/photos/avocado?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
        </ImageWithCaption>
        <BlogHeading3>
            How to include sweets in your meal plan
        </BlogHeading3>
        <Paragraph>
            Although sweets are technically forbidden on a insulin resistance diet, you can still introduce them tactfully. 
        </Paragraph>
        <Paragraph>
            Here are some of the <ExternalLink to="https://www.mayoclinic.org/diseases-conditions/diabetes/in-depth/diabetes-nutrition/art-20047654">recommended</ExternalLink> ways to do so:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>Calorie control. </b>Make sure you are eating well balanced meals, and that your caloric intake isn't too high when introducing a sweeter ingredient.</UnorderedListItem>
            <UnorderedListItem><b>Avoid empty calories.</b> Sugary drinks and processed foods that are low in fiber should be avoided.</UnorderedListItem>
            <UnorderedListItem><b>Swap carbs.</b> Include a sweet into your meal instead of another carb, making sure the number of calories of that meal remains the same as planned.</UnorderedListItem>
            <UnorderedListItem><b>Eat veggies and protein before carbs.</b> <ExternalLink to ="https://news.weill.cornell.edu/news/2015/06/food-order-has-significant-impact-on-glucose-and-insulin-levels-louis-aronne">A study has shown </ExternalLink>that leaving carbs for the end of your meal leads to lower post-meal sugar levels.</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Insulin resistance foods to avoid
        </BlogHeading2>
        <BlogHeading3>
            Processed foods
        </BlogHeading3>
        <Paragraph>
            The most important element of an insulin resistance diet is eliminating processed foods that are high in sugar, sodium, and contain a lot of preservatives.
        </Paragraph>
        <Paragraph>
            These include:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Sweets (candy, cakes, cookies, chocolate, ice cream, and similar)</UnorderedListItem>
            <UnorderedListItem>Chips, pretzels, and similar snack foods</UnorderedListItem>
            <UnorderedListItem>Canned soups</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Fast food
        </BlogHeading3>
        <Paragraph>
            Fast food can be high in saturated and trans fats, along with a high dose of carbs. Avoid it as much as possible.
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Hamburgers, cheeseburgers</UnorderedListItem>
            <UnorderedListItem>Pizza</UnorderedListItem>
            <UnorderedListItem>Fries</UnorderedListItem>
            <UnorderedListItem>Fried chicken</UnorderedListItem>
            <UnorderedListItem>Doughnuts</UnorderedListItem>
            <UnorderedListItem>Pastries</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Drinks that are high in sugar and carbs
        </BlogHeading3>
        <Paragraph>
            Sweet drinks often contain empty calories and have too much added sugar.
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Alcohol</UnorderedListItem>
            <UnorderedListItem>Fruit juices with added sugar</UnorderedListItem>
            <UnorderedListItem>Soda</UnorderedListItem>
            <UnorderedListItem>Sweetened coffee</UnorderedListItem>
            <UnorderedListItem>Plant based milks with added sugar (almond milk, soy milk, rice milk)</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Anything else that is high in glucose
        </BlogHeading3>
        <Paragraph>
            If it says glucose or sugar on the food label, you are best to leave it alone. Remember to always  read food labels before you try a new food product.
        </Paragraph>
        <BlogHeading2>
            How can I reverse insulin resistance quickly?
        </BlogHeading2>
        <Paragraph>
            Unfortunately, there is no quick fix to insulin resistance.
        </Paragraph>
        <Paragraph>
            However, the good news is that it is possible to completely reverse it (over a longer period of time)! 
        </Paragraph>
        <Paragraph>
            Reversing insulin resistance requires a dedicated and persistent change in lifestyle.
        </Paragraph>
        <ImageWithCaption>
            <StaticImage src="../assets/images/blogs/2022-17-woman-doing-crunches-on-the-mat.jpg" alt="Woman doing ab crunches on the mat."/>
            <em>Photo by <ExternalLink to="https://unsplash.com/@jonathanborba?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Jonathan Borba</ExternalLink> on <ExternalLink to = "https://unsplash.com/s/photos/exercise?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
        </ImageWithCaption>
        <Paragraph>
            Lifestyle changes for <ExternalLink to = "https://www.hsph.harvard.edu/nutritionsource/disease-prevention/diabetes-prevention/preventing-diabetes-full-story/">diabetes prevention</ExternalLink> you can consider:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>Start exercising.</b> Exercise is the simplest science backed way to increase your insulin sensitivity.</UnorderedListItem>
            <UnorderedListItem><b>Lose some weight.</b> You can reduce your risk of developing type 2 diabetes by <ExternalLink to = "https://www.nejm.org/doi/full/10.1056/nejmoa012512">50%</ExternalLink> if you lose only 5-7% of your weight.</UnorderedListItem>
            <UnorderedListItem><b>Get enough sleep.</b> Quality rest is as important as being active, so make sure you have a healthy sleeping schedule.</UnorderedListItem>
            <UnorderedListItem><b>Reduce stress.</b> High levels of the hormone cortisol caused by stress can cause other imbalances in the body and contribute to insulin resistance. Try mindfulness practices or start a hobby that has a calming effect on your mind.</UnorderedListItem>
            <UnorderedListItem><b>Change your diet.</b> As outlined in this article, a healthy low carb diet can greatly reduce your blood sugar levels and set you on the right path.</UnorderedListItem>
            <UnorderedListItem><b>Quit smoking.</b> Everyone knows smoking isn't good for you regardless of your insulin levels. Now you have another reason to quit - reducing your insulin resistance.</UnorderedListItem>
            <UnorderedListItem><b>Limit your alcohol consumption.</b> Alcohol is full of calories and can significantly raise blood sugar levels.</UnorderedListItem>
            <UnorderedListItem><b>Take medication.</b> Medications such as metformin are regularly prescribed for people living with type 2 diabetes, helping to control their blood sugar levels. If you have a prescribed therapy from your doctor, make sure to take it regularly.</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            Remember to always consult your doctor first when thinking about introducing any drastic changes to your lifestyle and/or diet.
        </Paragraph>
        <BlogHeading2>
            Which diets are good for insulin resistance
        </BlogHeading2>
        <Paragraph>
            In this article we have outlined the basics for creating a shopping list for an insulin resistance diet
        </Paragraph>
        <Paragraph>
            If you are interested in following a more specific meal plan, there are a few options.
        </Paragraph>
        <Paragraph>
            For example, <ExternalLink to = "https://diabetesjournals.org/spectrum/article/30/2/76/32278/DASH-Eating-Plan-An-Eating-Pattern-for-Diabetes">DASH (Dietary Approaches to Stop Hypertension)</ExternalLink> is a meal plan developed to treat hypertension without medication, but it does wonders for prediabetes and diabetes too, improving problems with obesity and insulin resistance.
        </Paragraph>
        <Paragraph>
            Another option that is trendy is following a keto diet. That means increasing healthy fats and protein and drastically cutting carb intake. 
        </Paragraph>
        <Paragraph>
            If you are interested, check out our <InternalLink to = "/keto-pantry-staples/">keto friendly pantry staples list.</InternalLink>
        </Paragraph>
        <Paragraph>
            Again, consult your doctor before starting a new eating plan, especially if you have been diagnosed with insulin resistance or diabetes.
        </Paragraph>
        <BlogHeading2>
            Grocery shopping tips & tricks
        </BlogHeading2>
        <Paragraph>
            If all of this seems like too much, don't worry!
        </Paragraph>
        <Paragraph>
            Improving your diet can seem like an endless amount of work, especially if you're on a busy schedule.
        </Paragraph>
        <Paragraph>
            One way to make it all so much easier is to up your grocery shopping game. That way your kitchen will always be fully stocked with everything you need for an insulin resistance diet.
        </Paragraph>
        <Paragraph>
            Here are some <ExternalLink to = "https://www.cdc.gov/diabetes/managing/eat-well/grocery-shopping.html">tips & tricks</ExternalLink> that could help you out on your next trip to the grocery store:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>Plan ahead. </b>Make an appropriate <ExternalLink to = "https://www.cdc.gov/diabetes/managing/eat-well/meal-plan-method.html">meal plan</ExternalLink> for the week ahead.</UnorderedListItem>
            <UnorderedListItem><b>Create a shopping list. </b>List what you will need for the upcoming week in advance.</UnorderedListItem>
            <UnorderedListItem><b>Eat before going to the store.</b> Going to the store hungry is more likely to make you buy unhealthy foods that would satisfy your cravings but wouldn't actually contribute to your health.</UnorderedListItem>
            <UnorderedListItem><b>Skip the processed foods sections.</b> Focus on the aisles with fresh produce, as well as those with spices, olive oil, beans, and lentils.</UnorderedListItem>
            <UnorderedListItem><b>Always check the nutrition facts.</b> Food labels can be misleading, so always look for the hard facts.</UnorderedListItem>
            <UnorderedListItem><b>Use substitutes.</b> Switch to diabetes-friendly sweeteners such as stevia.</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            Check out this article for more <InternalLink to = "/grocery-shopping-tips/">grocery shopping tips!</InternalLink>
        </Paragraph>
        <BlogHeading2>
            Conclusion
        </BlogHeading2>
        <Paragraph>
            Insulin resistance can lead to many health complications, but thankfully it is completely reversible.
        </Paragraph>
        <Paragraph>
            One of the best ways to reverse or manage insulin resistance is through diet. The most important aspect of the diet is to choose foods that are low in glycemic index.
        </Paragraph>
        <Paragraph>
            If you would like to follow more specific eating plans, you can try the keto diet or DASH.
        </Paragraph>
        <Paragraph>
            Another way to help you maintain a low sugar diet is to prepare in advance. This means always having on hand the insulin resistance diet shopping list outlined in this article.
        </Paragraph>
        <Paragraph>
            (Add the shopping list to your <InternalLink to = "/#download-section">shopping list app</InternalLink> to ensure that it always goes wherever you go!)
        </Paragraph>
        <Paragraph>
            Now that you are ready, go get some healthy food!
        </Paragraph>
    </StyledMainContent>
    </BlogPostWrapper>
}

export default BlogPost;