import React from "react";
import styled from "styled-components";

const StyledSubHeading = styled.h3`
  color: var(--primary-text-color);
  font-size: 1.5rem;
  font-weight: bold;
  margin: 40px 0;
`;

const BlogHeading3 = (props)=>{
    return <StyledSubHeading>
        {props.children}
    </StyledSubHeading>
}

export default BlogHeading3;